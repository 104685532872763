import { useEffect, useState } from "react";
import { ASCIIScene } from "./ascii/ascii_scene";

function App() {
  useAscii();
  const loaded = useFontLoadCheck();
  return <div className="App">{loaded && <ASCIIScene />}</div>;
}

export default App;

const useFontLoadCheck = () => {
  const [loaded, setLoaded] = useState(() =>
    document.fonts.check("12px Space Mono")
  );
  useEffect(() => {
    // console.log(`useFontLoadCheck ${loaded}`);
    if (!loaded) {
      // console.time("useFontLoadCheck load font");
      let shutdown = false;
      void (async () => {
        await document.fonts.load("12px Space Mono");
        if (!shutdown) {
          setLoaded(true);
        }
        // console.timeEnd("useFontLoadCheck load font");
      })();
      return () => {
        shutdown = true;
      };
    }
  }, [loaded]);

  return loaded;
};

const useAscii = () => {
  useEffect(() => {
    console.log(`











////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////==///////////////////////////////
//////////////////////////////////////////////////////////////////.  .//////////////////////////////
////////////////////////////////////////////////////////////////:      -////////////////////////////
//////////////////////////////////////////////////////////////=         .=//////////////////////////
/////////////////////////////////////////////////////////////.            ./////////////////////////
///////////////////////////////////////////////////////////:                -///////////////////////
/////////////////////////////////////////////////////////=                    =/////////////////////
////////////////////////////////////////////////////////.                      -////////////////////
//////////////////////////////////////////////////////:                      .//////////////////////
////////////////////////////////////////////////////=                       =///////////////////////
///////////////////////////////////////////////////.                      -/////////////////////////
/////////////////////////////////////////////////:                      .///////////////////////////
///////////////////////////////////////////////=                       +////////////////////////////
//////////////////////////////////////////////                       -//////////////////////////////
////////////////////////////////////////////:                       ////////////////////////////////
//////////////////////////////////////////=                       +/////////////////////////////////
/////////////////////////////////////////.                      :///////////////////////////////////
///////////////////////////////////////:                      ./////////////////////////////////////
/////////////////////////////////////=                       +//////////////////////////////////////
////////////////////////////////////.                      -////////////////////////////////////////
//////////////////////////////////:                      .//////////////////////////////////////////
////////////////////////////////=                       +///////////////////////////////////////////
///////////////////////////////.                      ://///////////////////////////////////////////
/////////////////////////////:                      .///////////////////////////////////////////////
///////////////////////////=                       =////////////////////////////////////////////////
//////////////////////////.                      -//////////////////////////////////////////////////
////////////////////////:                      .////////////////////////////////////////////////////
//////////////////////=                       +/////////////////////////////////////////////////////
/////////////////////                       -///////////////////////////////////////////////////////
/////////////////////                      /////////////////////////////////////////////////////////
//////////////////////=                  +//////////////////////////////////////////////////////////
////////////////////////:              :////////////////////////////////////////////////////////////
//////////////////////////.          .//////////////////////////////////////////////////////////////
///////////////////////////=        +///////////////////////////////////////////////////////////////
/////////////////////////////:    -#////////////////////////////////////////////////////////////////
/////////////////////////////#/..*//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////

/> interested in building with us? email hello@hntlabs.com
    
    `);
  }, []);
};
